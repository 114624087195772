<template>
	<div class="helpcenter">
		<div class="wrap">
			<div class="helpcenter__title">Справочный центр</div>
		</div>

		<router-link
			class="helpcenter__link"
			v-for="item in menu"
			:to="item.url"
			:key="item.title"
		>
			{{ item.title }}
		</router-link>
	</div>
</template>

<script>
export default {
	computed: {
		menu() {
			return [
				{
					title: 'Вопрос – ответ',
					url: '/profile/faq/questions'
				},
				{
					title: 'С чего начать',
					url: '/profile/faq/start'
				},
				{
					title: 'Полезные статьи и рекомендации',
					url: '/profile/faq/articles'
				}
			]
		}
	}
}
</script>
